@import url("https://rsms.me/inter/inter.css");
@import "~ngx-toastr/toastr";
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

html {
  font-family: "Inter", sans-serif;
}
@supports (font-variation-settings: normal) {
  html {
    font-family: "Inter var", sans-serif;
  }
}
html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: "Poppins", sans-serif !important;
}

/* Importing Bootstrap SCSS file. */
@import "~bootstrap/scss/bootstrap";

// #toast-container > .toast-success:before {
//   content: "\f004";
//   color: crimson;
// }

.p-sidebar .p-sidebar-header {
  background: #4082de !important;
  padding: 1rem;
}

.p-sidebar .p-sidebar-header .p-sidebar-close,
.p-sidebar .p-sidebar-header .p-sidebar-icon {
  width: 2rem;
  height: 2rem;
  color: #ffffff;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}
.mat-stepper-label-position-bottom .mat-horizontal-stepper-header,
.mat-vertical-stepper-header {
  padding: 24px 75px !important;
}

.modal-lg {
  max-width: 640px !important;
}

.modal {
  margin-top: 45px;
}
.mat-step-header {
  pointer-events: none !important;
}

.mat-progress-spinner circle,
.mat-spinner circle {
  stroke: #4b93ed !important;
}
@media screen and (max-width: 768px) {
  .mat-step-label {
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    min-width: 130px !important;
    vertical-align: middle;
    font-size: 14px;
    font-weight: 400;
  }
}
@media screen and (max-width: 767px) {
  .table-container {
    .table {
      margin-bottom: 19px;
      width: 800px;
    }
  }
}
@media screen and (max-width: 425px) {
  .mat-stepper-label-position-bottom .mat-horizontal-stepper-header,
  .mat-vertical-stepper-header {
    padding: 24px 40px !important;
  }
  .mat-step-label {
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    min-width: 130px !important;
    vertical-align: middle;
    font-size: 8px !important;
    font-weight: 500;
  }
}

// #spinner {
//   background-color: #dfecff !important;
//   opacity: 1.4 !important;
// }

::-webkit-scrollbar {
  width: 10px !important;
  height: 10px !important;
}

::-webkit-scrollbar-track {
  box-shadow: #888 !important;
  -webkit-box-shadow: #888 !important;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: #888 !important;
  -webkit-box-shadow: #888 !important;
  background-color: #888 !important;
  // background: red !important;
}

#toast-container > .toast-success {
  position: relative;
  overflow: hidden;
  width: 300px;
  border-radius: 3px 3px 3px 3px;
  background-position: 15px center;
  background-repeat: no-repeat;
  background-size: 24px;
  box-shadow: 0 0 12px #999999;
  color: #ffffff;
  font-weight: 500;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

label {
  margin-bottom: 5px;
}

// li.pagination-page.page-item.active.ng-star-inserted {
//   margin-bottom: 50px !important;
// }
ul.pagination.pagination-page-series {
  margin-top: 10px;
}
.fa-eye {
  color: #4b93ed;
  font-size: 20px;
  cursor: pointer;
}

/*****************************************************
	Custom styling example bellow.
*****************************************************/

.iti {
  display: block !important;
  margin-bottom: 20px;
}

.iti .dropdown-menu.country-dropdown {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-color: #c7cace;
  margin-top: -1px;
}

.iti .iti__country-list {
  box-shadow: none;
  font-size: 14px;
  margin-left: 0;
  width: 350px;
  max-height: 170px;
}

.iti__flag-container.open + input {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.iti .search-container input {
  font-size: 14px;
  border-color: #c7cace;
  border-radius: 0;
  padding: 5px 10px;
}

.iti .search-container input:focus {
  outline: none;
}

@media screen and (max-width: 479px) {
  .iti .iti__country-list {
    width: 88.3vw;
  }
}

@media screen and (max-width: 400px) {
  #main-footer {
    font-size: 12px !important;
  }
}

ngx-intl-tel-input input {
  height: 45px;
  margin-bottom: 20px;
  padding: 10px;
  border-style: solid;
  border-width: 1px;
  border-color: #c7cace;
  border-radius: 4px;
  font-size: 18px;
  width: 100%;
}

ngx-intl-tel-input.ng-invalid.ng-touched input {
  border: 1px solid #c0392b;
}

ngx-intl-tel-input input:hover {
  box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.24);
}

ngx-intl-tel-input input:focus {
  outline: none !important;
  border-color: #3498db;
  box-shadow: 0 0 0 0 #000;
}

ngx-intl-tel-input input::-webkit-input-placeholder {
  color: #bac2c7;
}

ngx-intl-tel-input input:-ms-input-placeholder {
  color: #bac2c7;
}

ngx-intl-tel-input input::-ms-input-placeholder {
  color: #bac2c7;
}

ngx-intl-tel-input input::placeholder {
  color: #bac2c7;
}

ngx-intl-tel-input input[disabled] {
  background-color: #e5eaf1;
}

.ngx-otp-input {
  width: 80% !important;
  height: 50px !important;
}

div#cdk-overlay-0 {
  min-width: 0px !important;
  margin-left: 31px !important;
}

.paidButton {
  height: 35px;
  border-radius: 60px;
  color: #228b22;
  background-color: #c9f29b;
  border-radius: 60px;
  border: none;
  width: 92px;
  font-weight: 500;
}

.unpaidButton {
  border-radius: 60px;
  line-height: 15px;
  color: #f2994a;
  background-color: rgba(242, 153, 74, 0.2);
  height: 35px;
  border: none;
  width: 92px;
  font-weight: 500;
}
